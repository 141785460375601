import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Seo } from '../components/utils'
import { TextContainer } from '../components/layout/TextContainer'

export default function AboutPage() {
  const { aboutData } = useStaticQuery(graphql`
    {
      aboutData: sanityAbout {
        title
        _rawBody
      }
    }
  `)
  return (
    <>
      <Seo title="Über Uns" />
      <TextContainer heading={aboutData.title} body={aboutData._rawBody} />
    </>
  )
}
